<template>
  <div class="contact-us min-main">
    <Header></Header>
    <div class="banner">
      <img
        class="banner-bg min-content"
        src="@/assets/img/pc/contactUs/banner.png"
      >
      <div class="banner-title">
        <h2>联系我们</h2>
        <div></div>
      </div>
    </div>
    <div class="map">
      <img src="@/assets/img/pc/contactUs/map-address.png">
    </div>
    <div class="contact">
      <div
        v-for="(item,index) in contacts"
        :key="index"
      >
        <h2>{{item.title}}</h2>
        <p>{{item.subTitle}}</p>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/pc/header'
import Footer from '@/components/pc/footer'

export default {
  data () {
    return {
      contacts: [{
        title: '官方客服电话：400-777-8802 (9:00-21:00)',
        subTitle: '此号码为易借速贷唯一官方服务电话，其他均为诈骗电话，请您提高警惕，谨防上当。'
      }, {
        title: '官方投诉电话：0571-87608950 (工作日 9:00-18:00)',
        subTitle: '若您收到任何由催收公司人员发出的含有侮辱、恐吓内容，或含有其他不文明、不合法内容的短信、电话\n等，您可通过投诉电话进行投诉。我们在收到您的投诉后将认真核实情况，并予以处理。'
      }, {
        title: '商务合作电话：17706521236',
        subTitle: '',
      }, {
        title: '媒体联系电话：15669948658',
        subTitle: '',
      }]
    }
  },
  mounted () {},
  methods: {},
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="less">
.contact-us {
  .banner {
    position: relative;
    &-bg {
      width: 100%;
      height: auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    &-title {
      width: 1300px;
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translateX(-50%);
      h2 {
        font-size: 32px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 45px;
        letter-spacing: 2px;
      }
      div {
        margin-top: 20px;
        width: 60px;
        height: 3px;
        background: #ffffff;
      }
    }
  }
  .map {
    margin: 0px auto;
    margin-top: 60px;
    width: 1300px;
    img {
      width: 1300px;
      height: 630px;
    }
  }
  .contact {
    width: 1300px;
    margin: 0px auto;
    padding-top: 80px;
    padding-bottom: 20px;
    div {
      margin-bottom: 40px;
      h2 {
        font-size: 20px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
        font-weight: 700;
        color: #1e1f20;
        line-height: 26px;
      }
      p {
        margin-top: 17px;
        font-size: 16px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        color: #4f4f4f;
        line-height: 21px;
        white-space: pre-line;
      }
    }
  }
}
</style>
